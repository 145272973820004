const initialState = {
    data: []
  }
  
  const CrudLocReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CREATE_LOC': {
        return {...state,
          data: action.data
        }  
      }
      case 'GET_LOCS': {
        return {...state,
          data: action.data
        }    
      }
  
      case 'DELETE_INVOICE':
        return { ...state }
      default:
        return { ...state }
    }
  }
  export default CrudLocReducer
    