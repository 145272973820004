import { combineReducers } from 'redux'
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import CrudLocReducer from './reducers/CrudLocReducer'
import CrudUserReducer from './reducers/CrudUserReducer'
import SgrlocProcessReducer from './reducers/SgrlocProcessReducer'
import ProfileReducer from './reducers/ProfileReducer'
import BillingReducer from './reducers/BillingReducer'
import RelatorioBiometriaReducer from './reducers/ReportsReducer/BiometriaReducer'
import RelatorioChecklistReducer from './reducers/ReportsReducer/ChecklistReducer'
import RelatorioSgrlocReducer from './reducers/ReportsReducer/SgrlocReducer'
import RelatorioSinistroReducer from './reducers/ReportsReducer/SinistroReducer'
import RelatorioCarBigDataReducer from './reducers/ReportsReducer/CarbigdataReducer'
import FaturamentoBiometriaReducer from './reducers/BillingsReducer/BiometriaReducer'
import FaturamentoChecklistReducer from './reducers/BillingsReducer/ChecklistReducer'
import FaturamenteSgrlocReducer from './reducers/BillingsReducer/SgrlocReducer'
import FaturamentoCarbigdataReducer from './reducers/BillingsReducer/CarbigdataReducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  rentals: CrudLocReducer,
  users: CrudUserReducer,
  sgrlocprocess: SgrlocProcessReducer,
  profile: ProfileReducer,
  billing: BillingReducer,
  relatorioBiometria: RelatorioBiometriaReducer,
  relatorioChecklist: RelatorioChecklistReducer,
  relatorioSgrloc: RelatorioSgrlocReducer,
  relatorioSinistro: RelatorioSinistroReducer,
  relatorioCarbigdata: RelatorioCarBigDataReducer,
  faturamentoBiometria: FaturamentoBiometriaReducer,
  faturamentoChecklist: FaturamentoChecklistReducer,
  faturamentoSgrloc: FaturamenteSgrlocReducer,
  faturamentoCarbigdata: FaturamentoCarbigdataReducer

})

export default rootReducer
