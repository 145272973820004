const initialState = {
    rate: [],
    month: [],
    week: [],
    day: [],
    ranking: [],
    reportHistory: [],
    isUpdated: false,
    reportToday: [],
    reportWeek: [],
    reportMonth: [],
    reportYear: []
}

const CarBigDataReducer = function (state = initialState, action) {
    switch (action.type) {
        case 'SET_REPORT_TYPE_ADMIN': {
            return {
                ...state,
                [action.timeFrame]: action.reportAdmin,
                isUpdated: true
            }
        }
        case 'SET_RATE': {
            return {
                ...state,
                rate: action.rate,
                isUpdated: true
            }
        }
        case 'SET_RANKING': {
            return {
                ...state,
                ranking: action.ranking,
                isUpdated: true
            }
        }
        case 'SET_REPORT_HISTORY': {
            return {
                ...state,
                reportHistory: action.reportHistory,
                isUpdated: true
            }
        }
        case 'SET_TODAY_REPORT_ADMIN': {
            return {
                ...state,
                reportTodayAdmin: action.reportTodayAdmin,
                isUpdated: true
            }
        }
        case 'SET_WEEK_REPORT_ADMIN': {
            return {
                ...state,
                reportWeekAdmin: action.reportWeekAdmin,
                isUpdated: true
            }
        }
        case 'SET_MONTH_REPORT_ADMIN': {
            return {
                ...state,
                reportMonthAdmin: action.reportMonthAdmin,
                isUpdated: true
            }
        }
        case 'SET_YEAR_REPORT_ADMIN': {
            return {
                ...state,
                reportYearAdmin: action.reportYearAdmin,
                isUpdated: true
            }
        }
        default: {
            return { ...state }
        }

    }
}

export default CarBigDataReducer
