const initialState = {
  accounts: [],
  isUpdated: false,
  firstAccessPassword: {}
}
/* eslint-disable */

const CrudLocReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ACCOUNT': {
      return {
        ...state,
        accounts: [...state.accounts, action.user],
        firstAccessPassword: {
          ...state.firstAccessPassword,
          [action.user.account_name]: action.firstAccessPassword
        }
      }
    }
    case 'GET_ALL_ACCOUNTS': {
      return { ...state, accounts: action.accounts, isUpdated: true }
    }
    case 'ACTIVATE_ACCOUNT': {
      const { account_id } = action.detail || {}; // Use default value {} if action.detail is undefined
      const updatedAccounts = state.accounts.map(account =>
        account.account_id === account_id
          ? { ...account, active: true }
          : account
      )
      return {
        ...state,
        accounts: updatedAccounts
      }
    }

    case 'DEACTIVATE_ACCOUNT': {
      const { account_id } = action.detail || {}; // Use default value {} if action.detail is undefined
      const updatedAccounts = state.accounts.map(account =>
        account.account_id === account_id
          ? { ...account, active: false }
          : account
      );
      return {
        ...state,
        accounts: updatedAccounts
      };
    }


    case 'GET_ACCOUNT': {
      return {
        ...state,
        accounts: action.user

      }
    }
    case 'DELETE_ACCOUNT': {
      return {
        ...state,
        accounts: state.accounts.filter(
          user => user.account_name !== action.account_name
        )
      }
    }
    case 'UPDATE_ACCOUNT': {
      return {
        ...state,
        accounts: state.accounts.filter(
          (user) => user.account_name !== action.account_name
        ),
      }
    }

    case 'UPLOAD_PEM': {
      return { ...state }
    }
    default: {
      return { ...state }
    }
  }
}
/* eslint-disable */

export default CrudLocReducer
