const initialState = {
    data: []
  }
  
  const RelatorioChecklistReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_RELATORIOGERAL': {
        return {
          ...state,
          data: action.data
        }
      }
        
      case 'DELETE_INVOICE':
        return { ...state }
      default:
        return { ...state }
    }
  }
  
  export default RelatorioChecklistReducer